<template>
  <div class="tencent-location-container">
    <div id="tencentMap" :style="{width:'100%',height:'430px'}"></div>
  </div>
</template>
<script>

import { getGpsPosition } from '@/api/customer/tmsyd'
import { validatenull } from '@/tdcommon/validate'
export default {
  name: 'd2-container-tencent-location',
  data () {
    return {
      lng: 0,
      lat: 0,
      marker: null, // 点标记实例
      infoWindow: null, // 信息窗实例
      map: null // 地图实例
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
    // this.initPage()
  },
  methods: {
    setCarPositionMsg (carno) {
      this.carNo = carno
      // 异步加载地图
      this.loadMapScript().then(() => {
        this.initMap()
      })
    },
    loadMapScript () {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://map.qq.com/api/gljs?v=1.exp&key=ZN5BZ-WMVLU-ZOCV5-GLL2K-F5R2Q-TOBUX`
        script.onload = () => {
          resolve()
        }
        script.onerror = () => {
          reject(new Error('Failed to load Tencent Map SDK'))
        }
        document.head.appendChild(script)
      })
    },
    initMap () {
      this.map = new TMap.Map(document.getElementById('tencentMap'), {
        center: new TMap.LatLng(39.984120, 116.307503), // 设置中心点坐标
        zoom: 14 // 设置地图缩放级别
      })
      this.getCarPositionMsg(this.carNo)
    },
    getCarPositionMsg (carno) {
      if (validatenull(carno)) {
        return ''
      } else {
        getGpsPosition(carno).then(response => { // 豫FB5520
          if (response.code === 0) {
            let resObj = response.data
            this.lng = (Number(resObj.lon) / 600000).toFixed(6)
            this.lat = (Number(resObj.lat) / 600000).toFixed(6)
            var center = new TMap.LatLng(this.lat, this.lng)
            this.map.setCenter(new TMap.LatLng(this.lat, this.lng)) // 坐标中心
            // 清空之前的标记
            if (this.marker) {
              this.marker.setMap(null)
              this.marker = null
            }
            // 初始化信息窗关闭
            if (this.infoWindow) {
              this.infoWindow.close()
            }
            // 添加新的标记
            this.marker = new TMap.MultiMarker({
              id: 'marker-layer', // 如果有唯一标识，可以使用id
              map: this.map,
              styles: {
                // 点标记样式
                'marker': new TMap.MarkerStyle({
                  'width': 24,
                  'height': 35,
                  'anchor': { x: 12, y: 35 },
                  'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
                })
              },
              geometries: [
                // 点标记数据数组
                {
                  // 标记位置(纬度，经度，高度)
                  'position': center,
                  'id': 'marker'
                }
              ]
            })
            // 初始化infoWindow
            this.infoWindow = new TMap.InfoWindow({
              map: this.map,
              position: new TMap.LatLng(this.lat, this.lng),
              offset: { x: 0, y: -32 }, // 设置信息窗相对position偏移像素
              content: '<div style="display: flex;flex-direction: column;align-items: flex-start;"><span style="font-weight: bold;">' + carno + '</span><span>' + resObj.adr + '</span></div>'
            })
            // 监听标注点击事件
            this.marker.on('click', this.openInfoWindow)
          } else {
            // 清空之前的标记
            if (this.marker) {
              this.marker.setMap(null)
              this.marker = null
            }
            // 初始化信息窗关闭
            if (this.infoWindow) {
              this.infoWindow.close()
            }
            let msg = ''
            if (!validatenull(response.msg)) {
              msg = '错误：' + response.msg
            } else {
              msg = ''
            }
            this.$alert('未找到此车牌号的定位信息！' + msg, '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
              }
            })
          }
        })
      }
    },
    openInfoWindow () {
      this.infoWindow.open()
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
  .amap-demo {
    height: 300px;
  }
  .toolbar {
    margin-top: 10px;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-tencent-location/index.vue"
}
</vue-filename-injector>
