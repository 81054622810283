import Vue from 'vue'

import d2Container from './d2-container'
import d2ContainerFrame from './d2-container-frame'
import d2ContainerQuery from './d2-container-query'
import d2ContainerQuerySetting from './d2-container-query-setting'
import d2ContainerTableHeader from './d2-container-table-header'
import d2ContainerMoreButton from './d2-container-more-button'
import d2ContainerTable from './d2-container-table'
import d2ContainerData from './d2-container-data'
import d2ContainerDataEdit from './d2-container-data-edit'
import d2ContainerDataMulHeader from './d2-container-data-mulheader'
import d2ContainerTableSingle from './d2-container-table-single'
import d2ContainerEdit from './d2-container-edit'
import d2ContainerPagination from './d2-container-pagination'
import d2ContainerAddress from './d2-container-address'
import d2ContainerDistrict from './d2-container-district'
import d2ContainerCascader from './d2-container-cascader'
import d2ContainerMember from './d2-container-member'
import d2ContainerTableSetting from './d2-container-table-setting'
import d2ContainerDept from './d2-container-dept'
import d2ContainerDeptMoreSelect from './d2-container-dept-moreselect'
import d2ContainerCompanySelect from './d2-container-company-moreselect'
import d2ContainerProgress from './d2-container-progress'
import d2ContainerInput from './d2-container-input'
import d2ContainerMsgList from './d2-container-msg-list'
import d2ContainerEchartsBar from './d2-container-echarts-bar'
import d2ContainerEchartsLine from './d2-container-echarts-line'
import d2ContainerEchartsPie from './d2-container-echarts-pie'
import d2ContainerEchartsOperateBar from './d2-container-echarts-operate-bar'
import d2ContainerEchartsOperateLine from './d2-container-echarts-operate-line'
import d2ContainerCompanyDefault from './d2-container-company-default'
import d2ContainerSite from './d2-container-site'
import d2ContainerPaginationSlotinput from './d2-container-pagination-slotinput'
import d2ContainerEchartsLineSupplyHistory from './d2-container-echarts-line-supplyhistory'
import d2ContainerDeptOperation from './d2-container-dept-operation'
import d2ContainerDeptDispatch from './d2-container-dept-dispatch'
import d2ContainerUsername from './d2-container-username'
import d2ContainerSettlemdmOther from './d2-container-settlemdm-other'
import d2ContainerSettlemdmPay from './d2-container-settlemdm-pay'
import d2ContainerEchartsChainGroupBar from './d2-container-echarts-chaingroup-bar'
import d2ContainerEchartsChainGroupLine from './d2-container-echarts-chaingroup-line'
import d2ContainerEchartsChainGroupPie from './d2-container-echarts-chaingroup-pie'
import d2ContainerForwardingFuzzy from './d2-container-forwarding-fuzzy'
import d2ContainerExportRecords from './d2-container-export-records'
import d2ContainerImport from './d2-container-import'
import d2ContainerYdLocation from './d2-container-yd-location'
import d2ContainerUploadAli from './d2-container-uploadAli'
import d2ContainerTmsHd from './d2-container-tms-hd'
import d2ContainerTencentLocation from './d2-container-tencent-location'

// 注意 有些组件使用异步加载会有影响
Vue.component('d2-container', d2Container)
Vue.component('d2-container-frame', d2ContainerFrame)
Vue.component('d2-icon', () => import('./d2-icon'))
Vue.component('d2-icon-svg', () => import('./d2-icon-svg/index.vue'))
Vue.component('d2-container-query', d2ContainerQuery)
Vue.component('d2-container-query-setting', d2ContainerQuerySetting)
Vue.component('d2-container-table-header', d2ContainerTableHeader)
Vue.component('d2-container-more-button', d2ContainerMoreButton)
Vue.component('d2-container-table', d2ContainerTable)
Vue.component('d2-container-table-single', d2ContainerTableSingle)
Vue.component('d2-container-edit', d2ContainerEdit)
Vue.component('d2-container-pagination', d2ContainerPagination)
Vue.component('d2-address', d2ContainerAddress)
Vue.component('d2-district', d2ContainerDistrict)
Vue.component('d2-cascader', d2ContainerCascader)
Vue.component('d2-member', d2ContainerMember)
Vue.component('d2-table-setting', d2ContainerTableSetting)
Vue.component('d2-dept', d2ContainerDept)
Vue.component('d2-dept-moreselect', d2ContainerDeptMoreSelect)
Vue.component('d2-company-moreselect', d2ContainerCompanySelect)
Vue.component('d2-progress', d2ContainerProgress)
Vue.component('d2-input', d2ContainerInput)
Vue.component('d2-msg-list', d2ContainerMsgList)
Vue.component('d2-data', d2ContainerData)
Vue.component('d2-data-edit', d2ContainerDataEdit)
Vue.component('d2-data-mulheader', d2ContainerDataMulHeader)
Vue.component('d2-echarts-bar', d2ContainerEchartsBar)
Vue.component('d2-echarts-line', d2ContainerEchartsLine)
Vue.component('d2-echarts-pie', d2ContainerEchartsPie)
Vue.component('d2-echarts-operate-bar', d2ContainerEchartsOperateBar)
Vue.component('d2-echarts-operate-line', d2ContainerEchartsOperateLine)
Vue.component('d2-company-default', d2ContainerCompanyDefault)
Vue.component('d2-site', d2ContainerSite)
Vue.component('d2-container-pagination-slotinput', d2ContainerPaginationSlotinput)
Vue.component('d2-echarts-line-supply', d2ContainerEchartsLineSupplyHistory)
Vue.component('d2-dept-operation', d2ContainerDeptOperation)
Vue.component('d2-dept-dispatch', d2ContainerDeptDispatch)
Vue.component('d2-username', d2ContainerUsername)
Vue.component('d2-settlemdmOther', d2ContainerSettlemdmOther)
Vue.component('d2-settlemdmPay', d2ContainerSettlemdmPay)
Vue.component('d2-echarts-chainGroup-bar', d2ContainerEchartsChainGroupBar)
Vue.component('d2-echarts-chainGroup-line', d2ContainerEchartsChainGroupLine)
Vue.component('d2-echarts-chainGroup-pie', d2ContainerEchartsChainGroupPie)
Vue.component('d2-forwarding-fuzzy', d2ContainerForwardingFuzzy)
Vue.component('d2-export-records', d2ContainerExportRecords)
Vue.component('d2-import', d2ContainerImport)
Vue.component('d2-ydLocation', d2ContainerYdLocation)
Vue.component('d2-tencentLocation', d2ContainerTencentLocation)
Vue.component('d2-uploadAli', d2ContainerUploadAli)
Vue.component('d2-tms-hd', d2ContainerTmsHd)
